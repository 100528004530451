import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import {
  ActionIcon,
  B2BIcon,
  BestBuyIcon,
  KomputronikIcon,
  MatterhornIcon,
  MCIcon,
  SportsmanIcon,
  VidaIcon,
  WholesalerIcon,
} from 'src/assets/icons/clients';
import SEO from 'src/components/SEO';
import Layout from 'src/components/Layout';
import Content from 'src/components/Content';
import SectionTitle from 'src/components/SectionTitle';
import { CommisionIcon, StockUpIcon, PercentageIcon } from 'src/assets/icons';
import BackgroundSection from 'src/components/BackgroundSection';
import Grid from 'src/components/Grid';
import { device } from 'src/theme/breakpoints';
import Button, { ButtonVariant } from 'src/components/Button';
import { colors } from 'src/theme/colors';
import { HeadingScope } from 'src/components/Heading';
import { useModal } from 'components/Modal/ModalContext';
import Platforms from 'components/Platforms';
import InfoCard from 'components/InfoCard';
import Accordion from 'components/Accordion';
import { AccordionVariant } from 'components/Accordion/constants';
import ContactForm from 'components/ContactForm';

const HeroWrapper = styled.div`
  background-color: ${colors.grey200};
  margin-top: -84px;
  padding: 88px 0 32px;

  @media ${device.laptopUP} {
    padding: 140px 0 72px;
  }
`;

const Hero = styled(Content)`
  align-items: center;
  column-gap: 32px;
  display: flex;

  @media ${device.desktop} {
    column-gap: 64px;
  }

  @media ${device.mobileDOWN} {
    display: grid;
    grid-template-columns: 1fr;

    & button {
      margin: 0 auto;
    }
  }
`;

const HeroContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const HeroHeading = styled.h1`
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
  max-width: 894px;

  @media ${device.laptop} {
    font-size: 50px;
  }

  @media ${device.desktop} {
    font-size: 60px;
  }
`;

const HeroText = styled.p`
  margin: 16px 0 30px;
  max-width: 724px;

  @media ${device.laptop} {
    font-size: 18px;
  }

  @media ${device.desktop} {
    font-size: 22px;
    margin: 40px 0 30px;
  }
`;

const HeroImage = styled.div`
  width: 40%;
  max-width: 420px;

  @media ${device.desktop} {
    max-width: 590px;
  }

  @media ${device.mobileDOWN} {
    display: none;
  }
`;

const HeroImageMobile = styled.div`
  margin: 16px auto 0;
  max-width: 206px;

  @media ${device.tabletUP} {
    display: none;
  }
`;

const EarnContent = styled.div`
  align-items: flex-start;
  display: flex;
  column-gap: 12%;
  padding-top: 42px;

  @media ${device.mobileDOWN} {
    flex-direction: column;

    & p,
    & h2 {
      text-align: center;
    }
  }
`;

const EarnDiagram = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileDOWN} {
    margin-top: 32px;
  }
`;

const Step = styled.div`
  align-items: flex-start;
  display: flex;
  column-gap: 62px;
  position: relative;

  @media ${device.mobileDOWN} {
    align-items: center;
    flex-direction: column;
  }

  &:not(:last-child) {
    margin-bottom: 64px;

    @media ${device.desktop} {
      margin-bottom: 100px;
    }
  }

  @media ${device.laptopSUP} {
    &:not(:last-child) {
      &:before {
        background-color: ${colors.grey200};
        content: '';
        height: 160px;
        left: 24px;
        position: absolute;
        top: 0;
        width: 1px;

        @media ${device.desktop} {
          left: 40px;
          height: 200px;
        }
      }
    }
  }
`;

const StepNumber = styled.div`
  align-items: center;
  background-color: ${colors.grey100};
  border: 1px solid ${colors.black};
  border-radius: 50%;
  display: flex;
  font-size: 20px;
  height: 46px;
  justify-content: center;
  flex: 0 0 46px;
  width: 46px;
  z-index: 1;

  @media ${device.mobileDOWN} {
    margin-bottom: 16px;
  }

  @media ${device.desktop} {
    height: 80px;
    font-size: 30px;
    flex: 0 0 80px;
  }
`;

const StepHeading = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 24px;

  @media ${device.desktop} {
    font-size: 23px;
  }
`;

const StepText = styled.p`
  margin: 0;
`;

const ProductDataPage: FC = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const features = [
    {
      image: <StockUpIcon />,
      title: t('affiliate_features_1_title'),
      children: t('affiliate_features_1_text'),
    },
    {
      image: <CommisionIcon />,
      title: t('affiliate_features_2_title'),
      children: t('affiliate_features_2_text'),
    },
    {
      image: <PercentageIcon />,
      title: t('affiliate_features_3_title'),
      children: t('affiliate_features_3_text'),
    },
  ];

  return (
    <Layout>
      <HeroWrapper>
        <Hero>
          <HeroContent>
            <HeroHeading>
              <Trans i18nKey="affiliate_main_heading" />
            </HeroHeading>
            <HeroImageMobile>
              <StaticImage
                src="../assets/images/affiliate.png"
                alt=""
                placeholder="blurred"
              />
            </HeroImageMobile>
            <HeroText>
              <Trans i18nKey="affiliate_main_text" />
            </HeroText>
            <Button variant={ButtonVariant.ALFA} onClick={openModal}>
              <Trans i18nKey="affiliate_start" />
            </Button>
          </HeroContent>
          <HeroImage>
            <StaticImage
              src="../assets/images/affiliate.png"
              alt=""
              placeholder="blurred"
            />
          </HeroImage>
        </Hero>
      </HeroWrapper>
      <HeadingScope>
        <Content>
          <SectionTitle
            subtitle={t('affiliate_section_1_subtitle')}
            centered
            introText={t('affiliate_section_1_text')}
          >
            <Trans i18nKey="affiliate_section_1_title" />
          </SectionTitle>
          <Grid columns={3}>
            {features.map((feature, i) => (
              <InfoCard
                key={i}
                icon={feature.image}
                title={feature.title}
                text={feature.children}
                largeIcon
              />
            ))}
          </Grid>
        </Content>
        <BackgroundSection>
          <EarnContent>
            <SectionTitle subtitle={t('affiliate_steps_subtitle')}>
              <Trans i18nKey="affiliate_steps_title" />
            </SectionTitle>
            <EarnDiagram>
              <Step>
                <StepNumber>1</StepNumber>
                <div>
                  <StepHeading>
                    <Trans i18nKey="affiliate_steps_1_title" />
                  </StepHeading>
                  <StepText>
                    <Trans i18nKey="affiliate_steps_1_text" />
                  </StepText>
                </div>
              </Step>
              <Step>
                <StepNumber>2</StepNumber>
                <div>
                  <StepHeading>
                    <Trans i18nKey="affiliate_steps_2_title" />
                  </StepHeading>
                  <StepText>
                    <Trans i18nKey="affiliate_steps_2_text" />
                  </StepText>
                </div>
              </Step>
              <Step>
                <StepNumber>3</StepNumber>
                <div>
                  <StepHeading>
                    <Trans i18nKey="affiliate_steps_3_title" />
                  </StepHeading>
                  <StepText>
                    <Trans i18nKey="affiliate_steps_3_text" />
                  </StepText>
                </div>
              </Step>
            </EarnDiagram>
          </EarnContent>
        </BackgroundSection>

        <Content>
          <SectionTitle
            subtitle={t('common_clients')}
            centered
            introText={t('common_they_trust_text')}
          >
            <Trans i18nKey="common_they_trust" />
          </SectionTitle>
          <Platforms
            id="clients"
            maxSlidesLaptop={10}
            maxSlidesDesktop={10}
            logos={[
              <ActionIcon key="action" />,
              <B2BIcon key="b2b" />,
              <BestBuyIcon key="bestbuy" />,
              <KomputronikIcon key="kompu" />,
              <MatterhornIcon key="matt" />,
              <MCIcon key="mc" />,
              <SportsmanIcon key="sports" />,
              <VidaIcon key="vida" />,
              <WholesalerIcon key="whole" />,
            ]}
          />
        </Content>

        <div id="faq">
          <BackgroundSection isDarker>
            <SectionTitle centered subtitle={t('common_additional_info')}>
              <Trans i18nKey="index_faq"></Trans>
            </SectionTitle>
            <Accordion
              variant={AccordionVariant.ALFA}
              items={[
                {
                  title: t('faq_1_title'),
                  content: t('faq_1_content'),
                },
                {
                  title: t('faq_2_title'),
                  content: t('faq_2_content'),
                },
                {
                  title: t('faq_3_title'),
                  content: t('faq_3_content'),
                },
                {
                  title: t('faq_4_title'),
                  content: (
                    <>
                      <Trans i18nKey="faq_4_content_0" />
                      <Button
                        variant={ButtonVariant.CHARLIE}
                        onClick={openModal}
                      >
                        <Trans i18nKey="faq_4_content_1" />
                      </Button>
                      <Trans i18nKey="faq_4_content_2" />
                    </>
                  ),
                },
                {
                  title: t('faq_5_title'),
                  content: t('faq_5_content'),
                },
                {
                  title: t('faq_6_title'),
                  content: t('faq_6_content'),
                },
                {
                  title: t('faq_7_title'),
                  content: t('faq_7_content'),
                },
                {
                  title: t('faq_8_title'),
                  content: t('faq_8_content'),
                },
              ]}
            />
          </BackgroundSection>
        </div>
      </HeadingScope>
      <ContactForm />
    </Layout>
  );
};

export default ProductDataPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Become an OmniGet Affiliate | OmniGet"
    description="Join the OmniGet Affiliate Program and earn commissions by promoting the leading product information management tool. Partner with us and grow your revenue."
  />
);
